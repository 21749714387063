// src/index.ts
import { fromJSProcessor } from "ethereum-indexer-js-processor";
import { StratagemsContract } from "stratagems-common";
var StratagemsIndexerProcessor = {
  // version is automatically populated via version.cjs to let the browser knows to reindex on changes
  version: "8b3313bcfbef95ebec1f226c09fbc948f38418201150466cbeff2c3082df81d8",
  //
  construct() {
    return {
      cells: {},
      owners: {},
      commitments: {},
      placements: [],
      points: {
        global: { lastUpdateTime: 0, totalRewardPerPointAtLastUpdate: 0n, totalPoints: 0n },
        fixed: {},
        shared: {}
      },
      computedPoints: {}
    };
  },
  onCommitmentRevealed(state, event) {
    const epoch = event.args.epoch;
    const account = event.args.player.toLowerCase();
    let epochEvents = state.placements.find((v) => v.epoch === event.args.epoch);
    if (!epochEvents) {
      epochEvents = {
        epoch,
        cells: {}
      };
      state.placements.unshift(epochEvents);
      if (state.placements.length > 7) {
        state.placements.pop();
      }
    }
    const stratagemsContract = new StratagemsContract(state, 7);
    for (const move of event.args.moves) {
      stratagemsContract.computeMove(event.args.player, event.args.epoch, move);
      let cell = epochEvents.cells[move.position.toString()];
      if (!cell) {
        epochEvents.cells[move.position.toString()] = cell = {
          players: []
        };
      }
      cell.players.push({
        color: move.color,
        address: account
      });
    }
    delete state.commitments[account];
  },
  onSinglePoke(state, event) {
    const stratagemsContract = new StratagemsContract(state, 7);
    stratagemsContract.poke(event.args.position, event.args.epoch);
  },
  onMultiPoke(state, event) {
    const stratagemsContract = new StratagemsContract(state, 7);
    for (const position of event.args.positions) {
      stratagemsContract.poke(position, event.args.epoch);
    }
  },
  onCommitmentCancelled(state, event) {
    const account = event.args.player.toLowerCase();
    delete state.commitments[account];
  },
  onCommitmentMade(state, event) {
    const account = event.args.player.toLowerCase();
    state.commitments[account] = { epoch: event.args.epoch, hash: event.args.commitmentHash };
  },
  onCommitmentVoid(state, event) {
    const account = event.args.player.toLowerCase();
    delete state.commitments[account];
  },
  onReserveDeposited(state, event) {
  },
  onReserveWithdrawn(state, event) {
  },
  // onTimeIncreased(state, event) {},
  // --------------------------
  onForceSimpleCells(state, event) {
    const stratagemsContract = new StratagemsContract(state, 7);
    stratagemsContract.forceSimpleCells(event.args.epoch, event.args.cells);
  },
  onAccounFixedRewardUpdated(state, event) {
    state.points.fixed[event.args.account] = event.args.fixedRateStatus;
  },
  onAccountSharedRewardUpdated(state, event) {
    state.points.shared[event.args.account] = event.args.sharedRateStatus;
  },
  onGlobalRewardUpdated(state, event) {
    state.points.global = event.args.globalStatus;
  }
};
var createProcessor = fromJSProcessor(() => StratagemsIndexerProcessor);
export {
  createProcessor
};
